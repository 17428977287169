import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Row, Container, Spinner } from 'react-bootstrap';
import { TiDownloadOutline } from 'react-icons/ti';

// api
import { getLoansByMonths, getLoans } from '../../api/provider';

// constans
import { MONTHS, API_EFILM } from '../../constans';

// own components
import BarChart from '../../components/charts/barChart';
import SelectMonth from '../../components/filters/SelectMonths';
import SelectYear from '../../components/filters/SelectYear';
import Table from '../../components/table';
import Button from '../../components/buton';

const Home = () => {
  const [loansMonth, setLoansMonth] = useState([])
  const [loans, setLoans] = useState()
  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")
  const [page, setPage] = useState(1)
  const [disabledBtn, setDisabledBtn] = useState(false)

  useEffect(() => {
    handleLoansByMonth()
  }, [year])

  useEffect(() => {
    handleLoans()
  }, [month, page])

  const setSelectMonth = (month) => {
    setMonth(month)
  }
  
  const setSelectYear = (year) => {
    setYear(year)
  }

  const serializerLoansByMonth = (data) => {
    return data.map(item => {
      return {mes: MONTHS[item.mes - 1], prestamos: item.prestamos}
    })  
  }
  
  const handleLoansByMonth = async () => {
    if(year !== "") {
      const response = await getLoansByMonths({'year': year})
      if (response.status === 200) {
        setLoansMonth(serializerLoansByMonth(response.data))
      }    
    }
  }

  const handleLoans = async () => {
    if(year !== "" && month !== ""){
      const response = await getLoans({year, month, page})
      if (response.status === 200) {
        setLoans(response.data)
      }
    }
  }

  const exportLoans = async() => {
    console.log("export");
    setDisabledBtn(true)
    axios({
      url: API_EFILM + "supplier-statistics/export-excel?year=" + year + "&month=" + month,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        'Authorization': 'JWT ' + localStorage.getItem('tokenAuth')
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'loans.xls');
      document.body.appendChild(link);
      link.click();
      setDisabledBtn(false)
    });
  }
  

  return (
    <Container>
      <Row>
        <Col md={6}>
          <SelectYear handleSelect={setSelectYear} />
        </Col>
      </Row>
      <Row>
        <Col style={{height: 450}}>
          <BarChart 
            data={loansMonth}  
            primary_key="mes"
            dataKeys={[{ key: "prestamos", color: "#faaa00" }]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SelectMonth year={year} handleSelect={setSelectMonth} />
        </Col>
        <Col md={4}>
          <Button disabled={disabledBtn} className="online-cprimary" onClick={exportLoans} >
            Exportar {
              disabledBtn ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (<TiDownloadOutline />)
            } 
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            header={["Audiovisual", "Fecha y hora"]}
            page={page}
            changePage={setPage}
            next={loans ? (loans.next ? true: false) : false}
            previous={loans ? (loans.previous ? true : false) : false}
          >
            {
              loans ? (
                loans.results.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.movie__title}
                    </td>
                    <td>
                      {item.fechaPrestamo}
                    </td>
                  </tr>
                ))
              ) : null
            }
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default Home