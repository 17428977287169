import React, { useContext} from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../contexts/UserContext';
import { setValueStorage } from '../../helpers';

import {
  Navbar,
  Nav
} from 'react-bootstrap';

const Header = () => {
  const userContext = useContext(UserContext)
  let history = useHistory()

  const logout = () => {
    setValueStorage("tokenAuth", "")
    userContext.setIsAuth(false)
    history.push("/")
  }
  
  return (
    <Navbar collapseOnSelect expand="lg" style={{background: "#01093f"}} variant="dark">
      <Navbar.Brand href="#home">eFilm Online</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
        </Nav>
        <Nav>
          <Nav.Link onClick={() => logout()} href="#">Cerrar sesión</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header;