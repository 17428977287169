import api from './Api';

export async function methodGet(url, query){
  try {
    const { status, data } = await api.get(url, {params: query})
    if( status === 200 ) {
      const response = { status, data }
      return response
    } else {
      alert("GET: algo ha ido mal " + url + status)
    }
  } catch (error) {
    const response = { status: error.response.status }
    return response
  }
}

export async function methodPost(url, form_data){
  try {
    const { status, data } = await api.get(url, JSON.stringify(form_data))
    if( status === 201 ) {
      const response = { status, data }
      return response
    } else {
      alert("POST: algo ha ido mal " + url + status)
    }
  } catch (error) {
    const response = { status: error.response.status }
    return response
  }
}

export async function methodDelete(url){
  try {
    const { status } = await api.delete(url)
    if ( status === 204 ){
      const response = { status }
      return response
    } else {
      alert("DELETE: algo ha ido mal " + url + status)
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}