import React, { useState, useEffect } from 'react';

// api
import { getMonths } from '../../api/provider';

// OWN COMPONENTS
import Select from '../select';

// constans 
import { MONTHS } from '../../constans';

const SelectMonths = (props) => {
  const [options, setOptions] = useState([{value: "", name: "-- Selecciona un mes --"}])
  
  useEffect(() => {
    loadMonths()
  }, [props.year])
  
  const loadMonths = async() => {
    const response = await getMonths({year: props.year})
    if( response.status === 200 ){
      let data = response.data.map(item => (
        {value: item.month, name: MONTHS[item.month - 1]}
      ))
      setOptions(data)     
      props.handleSelect(data[0].value) 
    }
  }
  

  return (
    <Select options={options} handleSelect={props.handleSelect}  />
  )
}

export default SelectMonths;