import { methodGet } from './methods';
import { API_EFILM } from '../constans';

export async function getLoansByMonths(query){
  const url = API_EFILM + "supplier-statistics/by-months"
  return methodGet(url, query)
}

export async function getYears(){
  const url = API_EFILM + "supplier-statistics/years"
  return methodGet(url, {})
}

export async function getMonths(query){
  const url = API_EFILM + "supplier-statistics/months"
  return methodGet(url, query)
}

export async function getLoans(query) {
  const url = API_EFILM + "supplier-statistics/loans"
  return methodGet(url, query)
}