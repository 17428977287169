import React, { useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/img/efilm.png';

// own components
import Card from '../../components/card';
import Input from '../../components/input';
import Button from '../../components/buton';

// calls api
import { authUser } from '../../api/user';

import { UserContext } from '../../contexts/UserContext';

import { setValueStorage } from '../../helpers';

const Login = () => {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [msgError, setMsgError] = useState("")
  let history = useHistory()
  const userContext = useContext(UserContext)

  const handleSubmit = async () => {
    if(user === "" || password === "") {
      setMsgError("Los campos son obligatorios");
    } else {
      const response = await authUser({username: user, password});
      if ( response.status === 200) {
        const { token } = response
        setValueStorage('tokenAuth', token)
        userContext.setIsAuth(true)
        history.push("/")
      } else {
        setMsgError("El usuario o la contraseña son incorrectos.")
      }
    }
  } 

  return (
      <div className="container">
        <Row  style={{display: 'flex', justifyContent: 'center'}}>
          <Col md={5} style={{paddingTop: 45, paddingBottom:45}}>
            <Card 
              title="Iniciar sesión" 
              className="bgd-secondary"
              img={logo}
            >
              <Input value={user} onChange={e => setUser(e.target.value)} type="text" placeholder="Usuario" />
              <Input value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="Contraseña" />
              {msgError !== "" ? (
                <Row>
                  <Col md={12}>
                    <div className="msg-error">
                      {msgError}
                    </div>
                  </Col>
                </Row>
              ) : (null)}
              <Row className="justify-content-end" style={{paddingRight: '1.24rem'}}>
                <Button className="online-cprimary" onClick={handleSubmit} >
                  Iniciar sesión
                </Button>
              </Row>
            </Card>
          </Col>
          
        </Row>
      </div>
  )
}

export default Login;