import React from 'react';

import { Card } from 'react-bootstrap';

const CardCustom = (props) => {
  return (
    <Card style={{width: '100%', paddingBottom: 20}} {...props}>
      <Card.Body>
        {props.img ? (
          <div style={{textAlign: 'center', marginTop: 20, marginBottom: 15}}>
            <img src={props.img} height={65} alt="Logo efilm" /> 
          </div>
        ) : (null)}
        <Card.Title>{props.title}</Card.Title>
        {props.children}
      </Card.Body>
    </Card>
  )
  
}

export default CardCustom;