import React, { createContext, useState } from "react";

//helpers
import { getValueStorage } from "../helpers";
export const UserContext = createContext();

const UserContextProvider = props => {
  const checkLogged = getValueStorage("tokenAuth") ? true : false;
  const [isAuth, setIsAuth] = useState(checkLogged);
  const [user, setUser] = useState({});

  return (
    <UserContext.Provider value={{ isAuth, setIsAuth, user }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
