import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

// own contexts 
import { UserContext } from '../contexts/UserContext';

// own layout
import DefaultLayout from '../layouts/DefaultLayout';
import SecondaryLayout from '../layouts/SecondaryLayout';

// own pages
import Home from '../pages/home';
import Login from '../pages/login';


const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest}
      render={ props => rest.isAuth ? (
        <Redirect 
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      ): (
        <SecondaryLayout >
          <Component {...props}  />
        </SecondaryLayout>
      ) }
    />
  )
}



const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest}
      render={props => rest.isAuth ? (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )}
    />
  )
}

const MainRoute = () => {
  const { isAuth } = useContext(UserContext)
  return (
    <Router>
      <Switch>
        <LoginRoute 
          isAuth={isAuth}
          exact
          path="/login"
          component={Login}
        />
        <AdminRoute 
          isAuth={isAuth}
          exact
          path="/"
          component={Home}
        />  
      </Switch>
    </Router>
  )
}

export default MainRoute;