import React from 'react';


const Footer = () => {
  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="footer-copyright text-center py-3">© {new Date().getFullYear()} Copyright:
        <a className="link-primary" href="https://efilm.online"> eFilm Online</a>
      </div>
    </footer>

  )
}

export default Footer;