import api from "./Api";
import { API_EFILM } from '../constans';

export async function authUser(query) {
  try {
    const { status, data } = await api.post(API_EFILM + 'auth-user/', JSON.stringify(query))
    if (status === 200){
      const response = {status, token: data.token}
      return response
    }
  } catch (error) {
    if (error.request.status === 0){
      const status = error.request.status
      const response = {status, token: null}
      return response
    }
    const status = error.response.status
    const response = {status, token: null}
    return response
  }
}
